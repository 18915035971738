<template>
  <v-row>
    <v-col cols="12">
      <h2>{{ $t("coupon_management.head") }}</h2>
    </v-col>
    <v-col cols="12">
      <v-card>
        <v-card-title v-if="canCreate"> {{ $t("coupon_management.head2") }} </v-card-title>
        <v-form v-if="canCreate" ref="form" class="d-flex">
          <v-card-actions class="pb-0 d-flex align-start">
            <v-row no-gutters>
              <span>
                <text-input :text="couponForm.name" :label="$t('coupon_management.name')" :rules="textRules"
                  @setValue="couponForm.name = $event" />
              </span>
              <span>
                <text-input :text="couponForm.credit" :label="$t('coupon_management.value')" :rules="rules"
                  @setValue="couponForm.credit = $event" />
              </span>
              <span>
                <text-input :text="couponForm.turn_over" label="Turn Over" :rules="rules"
                  @setValue="couponForm.turn_over = $event" />
              </span>
              <span>
                <text-input :text="couponForm.set" :label="$t('coupon_management.quantity')" :rules="rules"
                  @setValue="couponForm.set = $event" />
              </span>
              <span>
                <text-input :text="couponForm.max_use" :label="$t('coupon_management.max_per_code')" :rules="rules"
                  @setValue="couponForm.max_use = $event" />
              </span>
              <!-- <span>
                <text-input
                  :text="couponForm.count"
                  label="สร้างจำนวน(คูปอง)"
                  :rules="rules"
                  @setValue="couponForm.count = $event"
                />
              </span>
              <span>
                <text-input
                  :text="couponForm.set"
                  label="จำนวนคูปอง/ชุด"
                  :rules="rules"
                  @setValue="couponForm.set = $event"
                />
              </span> -->

              <span>
                <date-picker-input :label="$t('coupon_management.date_from')" :date="couponForm.time_use"
                  @setValue="couponForm.time_use = $event" />
              </span>
              <span>
                <date-picker-input :label="$t('coupon_management.date_to')" :date="couponForm.time_expire"
                  @setValue="couponForm.time_expire = $event" />
              </span>

              <v-btn color="primary" class="" @click="confirmAddCouponData"> {{ $t('coupon_management.create') }} </v-btn>
            </v-row>
          </v-card-actions>
        </v-form>
        <v-card-actions>
          <v-spacer />
          <span class="px-3 mt-5">{{ $t('search') }}:</span>
          <div style="width: 150px" class="mt-5">
            <v-text-field v-debounce:500ms="searchKeyword" outlined dense hide-details />
          </div>
        </v-card-actions>
        <v-card-actions class="pt-0">
          <v-data-table mobile-breakpoint="0" style="width: 100%" :headers="headers" :items="value"
            :no-data-text="$t('not_found')" :no-results-text="$t('not_found')" :options.sync="pagination"
            :server-items-length="pagination.totalItems" :items-per-page="pagination.rowsPerPage"
            :page.sync="pagination.page" :footer-props="{
              'items-per-page-options': [10, 25, 30, 40, 50],
            }" :loading="loading" :search="search">
            <template v-slot:[`item.actionDetail`]="{ item }">
              <v-btn color="primary" class="white--text text-capitalize"
                @click="$router.push(`/coupon/coupon_list/${item.name}_${item.ref}/${formatDate(item.created_at)}`)">
                {{ $t('coupon_management.header8') }}
              </v-btn>
            </template>
            <template v-slot:[`item.credit`]="{ item }">
              {{ item.credit | currency }}
            </template>
            <template v-slot:[`item.turn_over`]="{ item }">
              {{ item.turn_over | currency }}
            </template>
            <template v-slot:[`item.totalCredit`]="{ item }">
              {{ item.totalCredit | currency }}
            </template>
            <template v-slot:[`item.created_at`]="{ item }">
              <span>{{ formatDateTime(item.created_at) }}</span>
            </template>
            <template v-slot:[`item.action`]="{ item }">
              <v-btn v-if="!item.isCleared" color="error" class="white--text text-capitalize"
                @click="deleteCouponSet(item)"> {{ $t('clear') }}
              </v-btn>
            </template>
          </v-data-table>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import DatePickerInput from '@/components/input/DatePickerInput.vue'
import TextInput from '@/components/input/TextInput.vue'
import store from '@/store'
import coupon from '@/store/coupon'
export default {
  components: {
    DatePickerInput,
    TextInput,
  },

  data() {
    return {
      date: null,
      currentMemberDetail: {},
      couponForm: {
        credit: '',
        turn_over: '',
        time_use: moment().format('YY-MM-DD'),
        time_expire: moment().add(90, 'days').format('YY-MM-DD'),
        count: '0',  // จำนวนยูสที่ใช้ได้ ต่อ คูปอง
        set: '',  // จำนวนคูปอง ต่อ ชุด
        max_use: '',  // จำนวนการใช้ ต่อคูปอง
      },
      search: '',
      loading: false,
      canCreate: false,
      rules: [
        value => !!value || this.$t('alert_list.list1'),
        value => (!!value && /^\d+$/.test(value)) || this.$t('alert_list.list2'),
      ],
      textRules: [
        value => !!value || this.$t('alert_list.list1'),
        value => value.trim().length > 0 || this.$t('alert_list.list1'),
        value => !/\s{2,}/.test(value) || this.$t('alert_list.list1'),
        value => /^[a-zA-Z0-9_-]+$/.test(value) || this.$t('alert_list.list1')
      ],
      headers: [
        {
          text: this.$t('coupon_management.header10'),
          value: 'name',
          width: '100px',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('coupon_management.header11'),
          value: 'ref',
          width: '100px',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('coupon_management.header1'),
          value: 'totalSet',
          width: '100px',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('coupon_management.header2'),
          value: 'credit',
          width: '100px',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('coupon_management.header3'),
          value: 'turn_over',
          width: '95px',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('coupon_management.header4'),
          value: 'created_at',
          width: '95px',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('coupon_management.header5'),
          value: 'time_use',
          width: '95px',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('coupon_management.header6'),
          value: 'time_expire',
          width: '95px',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('coupon_management.header7'),
          value: 'totalCredit',
          width: '95px',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('coupon_management.header8'),
          value: 'actionDetail',
          width: '95px',
          align: 'center',
          sortable: false,
        },
      ],
      value: [],
      pagination: {
        page: 1,
        rowsPerPage: 25,
        totalItems: 0,
      },
    }
  },
  async created() {
    this.addLogPage()
    this.getPermissionsEdit()
  },
  watch: {
    pagination: {
      async handler(newValue, oldValue) {
        let cont = false
        if (oldValue.page !== newValue.page) cont = true
        if (oldValue.itemsPerPage !== newValue.itemsPerPage) cont = true
        if (oldValue.sortBy !== newValue.sortBy) cont = true
        if (oldValue.sortDesc !== newValue.sortDesc) cont = true
        if (cont) {
          this.loading = true
          const result = await this.getCouponData(newValue.page, newValue.itemsPerPage)
          this.value = result.data
          this.pagination.totalItems = result.count
          this.loading = false
        }
      },
      deep: true,
    },
  },
  methods: {
    async addLogPage() {
      try {
        let data
        const userSystem = store.getters.getuserInfo
        await axios.get('https://api.ipify.org?format=json').then(res =>
          data = {
            ip: res.data.ip,
            name: 'คูปอง',
            url: window.location.href,
            detail: 'ระบบจัดการคูปอง',
            admin: userSystem.name ? userSystem.name : '',
          }
        )
        await this.$store.dispatch('addLogPage', data)
      } catch (e) {
        // if (!e.message.includes('Unexpected token') || !e?.includes('Unexpected token') || !e.message.includes('Unexpected identifier')) {
        //   this.$swal.fire({
        //     icon: 'error',
        //     title: e.message,
        //   })
        // }
      }
    },
    async getPermissionsEdit() {
      let psSystem = store.getters.getPermissionsEdit
      let chkEdit = psSystem.filter(el => el === 'coupon')
      if (chkEdit && chkEdit?.length > 0) {
        this.canCreate = true
        this.headers = [...this.headers,
        {
          text: this.$t('coupon_management.header91'),
          value: 'action',
          width: '95px',
          align: 'center',
          sortable: false,
        }
        ]
      }
    },
    async getCouponData(page, row, search) {
      try {
        let searchText = search ? `&search=${search}` : '';
        let resData = await this.$store.dispatch('getCoupon', `page=${page}&rows=${row}${searchText}`)
        if (resData.data && resData.data.length > 0) {
          const { count, data } = resData
          let convertData = data.map((item) => {
            let splitName = item.name ? item.name.split("_") : null;
            let couponName, refCode;
            if (splitName) {
              refCode = splitName ? splitName[splitName.length - 1] : null;
              couponName = splitName.slice(0, -1).join("_")
            }
            return {
              name: couponName,
              ref: refCode,
              totalSet: item.totalSet,
              credit: item.credit,
              totalCredit: item.totalCredit * item.max,
              turn_over: item.turn_over,
              time_use: item.time_use,
              time_expire: item.time_expire,
              detail: item.detail,
              created_at: item.created_at,
              isCleared: item.isCleared
            }
          });

          let formattedData = { count, data: convertData }
          return formattedData
        } else {
          return resData;
        }
      } catch (e) { }
    },
    confirmAddCouponData() {
      if (this.$refs.form.validate()) {
        this.$swal
          .fire({
            icon: 'warning',
            title: this.$t('alert'),
            text: this.$t('coupon_management.create_confirm'),
            showCancelButton: true,
            confirmButtonText: this.$t('confirm'),
            cancelButtonText: this.$t('cancel'),
          })
          .then(async result => {
            if (result.isConfirmed) {
              await this.addCouponData()
              // this.$swal.fire('Saved!', '', 'success')
            }
          })
      }
    },

    async addCouponData() {
      try {
        const couponName = this.couponForm?.name.trim()
        const data = {
          name: (this.couponForm?.name && this.couponForm?.name !== "") ? this.couponForm.name : `${this.couponForm.set}_${this.couponForm.credit}_${this.couponForm.turn_over}_${this.couponForm.time_use}_${this.couponForm.time_expire}`,
          credit: parseInt(this.couponForm.credit),
          turn_over: parseInt(this.couponForm.turn_over),
          time_use: this.couponForm.time_use,
          time_expire: this.couponForm.time_expire,
          count: parseInt(this.couponForm.count), // จำนวนยูสที่ใช้ได้ ต่อ คูปอง
          set: parseInt(this.couponForm.set), // จำนวนคูปอง ต่อ ชุด
          max: parseInt(this.couponForm.max_use),//จำนวนสูงสุดที่ใช้ได้ ต่อ คูปอง
        }

        await this.$store.dispatch('addCoupon', data)
        this.loading = true
        const result = await this.getCouponData(1, this.pagination.itemsPerPage)
        this.value = result.data
        this.$refs.form.reset()
        this.couponForm = {
          credit: '',
          turn_over: '',
          time_use: moment().format('YY-MM-DD'),
          time_expire: moment().add(90, 'days').format('YY-MM-DD'),
          count: '1',
          set: '',
        }
        this.loading = false
      } catch (e) {
        if (!e.message.includes('Unexpected token') || !e?.includes('Unexpected token') || !e.message.includes('Unexpected identifier')) {
          this.$swal.fire({
            icon: 'error',
            title: e,
          })
        }
      }
    },
    async deleteCouponSet(item) {
      this.$swal
        .fire({
          icon: 'warning',
          title: this.$t('alert'),
          text: `${this.$t('coupon_management.clear_confirm')}: ${item.name}?`,
          showCancelButton: true,
          confirmButtonText: this.$t('confirm'),
          cancelButtonText: this.$t('cancel'),
        })
        .then(async result => {
          if (result.isConfirmed) {
            const createdAt = moment(item.created_at).format('YYYY-MM-DD HH:mm')
            let params = item.ref ? `name=${item.name}_${item.ref}` : `name=${item.name}&createdAt=${createdAt}`;
            await this.$store.dispatch('deleteCouponSet', params)
            this.$swal.fire(this.$t('coupon_management.clear_successful'), '', 'success')
            const result = await this.getCouponData(this.pagination.page, this.pagination.itemsPerPage)
            this.pagination.totalItems = result.count
            this.value = result.data
            this.loading = false
          }
        })
    },
    async searchKeyword(value) {
      this.loading = true
      let result = {}
      if (value) {
        result = await this.getCouponData(1, this.pagination.itemsPerPage, value)
      } else {
        result = await this.getCouponData(1, this.pagination.itemsPerPage)
      }
      this.pagination.totalItems = result.count
      this.value = result.data
      this.pagination.page = 1
      this.loading = false
    },
    formatDate(date) {
      return moment(date).format('YY-MM-DD HH:mm')
    },
    formatDateTime(date) {
      return moment(date).format('YYYY-MM-DD HH:mm:ss')
    },
  },
}
</script>
